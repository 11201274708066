/* mainfeed.css */

.main-feed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }
  
  .landscape-design {
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column; 
    width: 100%;
    /* padding-bottom: 5%; */
  }
  
  .text-box,
  .image-upload {
    padding: 10px;
    display: flex;
    flex-direction: column; /* Adjust to stack content vertically */
    flex: 1; /* Expand to fill available space */
  }
  
  .text-box textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    margin-bottom: 10px; /* Add margin to separate textarea from button */
  }
  
  .landscape-design img {
    width: 100%;
    height: 100%; /* Set height to 100% */
    object-fit: cover;
    aspect-ratio: 1/1; /* Maintain a square aspect ratio */
  }
  
  /* Add this style to create a special layout for the first two items */
  .landscape-design.special-design {
    grid-column: span 1; /* Set to span one column */
  }
  
  /* Add this style to make the special items the same size as existing image items */
  .special-design .text-box,
  .special-design .image-upload {
    flex: 1; /* Expand to fill available space */
  }
  
  .image-upload {
    padding: 10px;
    margin: 8px;
  }
  
  /* Set the same height for all items in each row */
  .main-feed .landscape-design {
    align-items: stretch;
  }
  
  .main-buy-button-container {
    margin-top: 3%;
  }