.gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 20px;
    /* background-color: #f4f4f4; */
  }
  
  .gallery-item {
    width: calc(25% - 10px);
    height: 200px;
    /* background-color: #e0e0e0; */
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder {
    background-color: #ccc;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  